import { observable, action } from 'mobx';
import { RegistrationPre } from '../domain/registration-pre';
import api from '../api';

export class RegistrationPreStore {
  @observable isLoading = false;

  @observable registrationInput: Partial<RegistrationPre> = {
    referral_code: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.registrationInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.referral_code = (request.referral_code == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  confirmReferralCode = async () => {
    //confirmReferralCode
    this.isLoading = true;
    try {
      let dat = {
          referral_code: this.registrationInput.referral_code
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      const result2 = await api.confirmReferralCode(result.token, dat);
      //console.log('======= confirmReferralCode =======');
      //console.log(result2);
      //console.log('============================');
      if(result2.status){
        return true;
      }
      else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }

  }

  setReferralCode(refcode) {
    this.registrationInput.referral_code = refcode;
  }

}

export const registrationPreStore = new RegistrationPreStore();
(window as any).registrationPreStore = registrationPreStore;
