import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const Membership: React.SFC = () => {
  return (
    <div className="readMoreContent">
      <h2>What is membership?</h2>
      <p>
      The clamour of the city ebbs away as you are welcomed into your home-away-from-home.
      A swipe of your access card grants entry. Be swept upstairs – either in our
      century-old elevator car, or wander up the majestic staircase. Cherish a moment
      of respite in the sanctuary of the magnificent Members'-only Library. Catch
      up on emails over free high-speed WiFi in the Business Suite. Sip a cold
      draught from a pewter tankard at the longest bar in Africa. Drive your deal
      home in one of the Club’s elegant meeting rooms. Celebrate your special occasion.
      Spend the night in one of the Club’s en suite bedrooms and wake to the unmistakable
      sound of the city. Travelling? Get in touch with your Membership Secretary to
      arrange accommodation at any one of over 200 reciprocal clubs worldwide.
      Wherever you go, <strong>Rand Club</strong> membership opens doors.
      </p>
      <p>
      <strong>Rand Club</strong> is your club – a private space for your use and enjoyment.
      </p>
    </div>
  );
};
export const Why: React.SFC = () => {
  return (
    <div className="readMoreContent">
      <h2>
        Why the <strong>Rand Club</strong>?
      </h2>
      <p>
      </p>
    </div>
  );
};
export const History: React.SFC = () => {
  return (
    <div className="readMoreContent">
      <h2>
        <strong>Rand Club</strong> heritage
      </h2>
      <p>
        Rand Club was founded in October 1887, a year after the establishment of the new township of Johannesburg.
      </p>
      <p>
        Prior to the sensational discovery of gold, this stretch of veld and farmland a mile above sea level was windswept and largely treeless.  The Witwatersrand (White Waters Ridge) was home to the farm Langlaagte and when traces of gold were found in a rocky outcrop on the farm property in February 1886, samples of the rock were sent to Kimberley to be assayed. Finding the results of the gold-bearing rock to be satisfactory, Cecil John Rhodes asked Dr Hans Sauer, the first district surgeon of Johannesburg, to return to the Rand and obtain options for ground. In December 1886, after completing some business, Rhodes suggested to Sauer that they should take a walk around the new town layout and select a site for a club.
      </p>
      <p>
        After walking for some time Rhodes suddenly stopped and said, “This corner will do for the club”.
      </p>
      <p>
        This was at the point where Commissioner Street met Loveday Street, running from Marshall’s township into Market Square. The site consisted of four stands, and Sauer was instructed to find out who these stands belonged to and buy them. Sauer discovered that two of the stands belonged to Ikey Sonnenberg, a financier and speculator. Upon being told that Rhodes wanted to buy the stands as a site for a gentlemens’ club, Sonnenberg immediately offered his two stands as a gift for this purpose. The other two stands belonged to a Scotsman called H.B. Marshall, to whom Sauer paid £72 for his stands.
      </p>
      <p>
        The first Rand Club was built with shares of £10 purchased by each candidate wishing to become a member, and thus the money required for building the clubhouse was raised quickly. The first clubhouse was a modest brick and thatch pavilion consisting of a single floor, but it offered welcome respite from the harsh elements in the new mining town. It served its purpose until 1889, after which it was levelled and a more commodious clubhouse was erected.
      </p>
    </div>
  );
};


interface Props {
  onClick: () => void;
  text: string;
}

export const ReadMoreButton: React.SFC<Props> = props => {
  return (
    <Button
      style={{
        margin: '0 8px',
        color: 'white',
        borderColor: 'white',
        background: 'rgba(0,0,0,0)'
      }}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
};

class Landing extends Component {
  state = {
    membershipOpen: false,
    whyOpen: false,
    historyOpen: false,
  };

  render() {
    return (
      <div className="fullscreen">
        <nav>
          <a href="/">
            <img alt='logo' src="logo.png" height="40px" />
          </a>
        </nav>

        <main className="landing-page-cover">
          <div id="section-landing-page-cover">
            <h2 className="outline">Your home away from home!</h2>
            <h4 className="outline">Experience the grandeur of a bygone era.</h4>

            <br/>
            <Row>
              <Col sm="12">
                <a
                  style={{ margin: 10 }}
                  className="btn btn-lg btn-primary"
                  href="/test"
                >
                  Welcome
                </a>
              </Col>
            </Row>
            <br/>

            <p
              style={{
                background: 'rgba(50,50,50,0.6)',
                padding: '16px',
                borderRadius: '4px'
              }}
            >
              <h2>
                <strong>A 21st century club. An extraordinary venue.</strong>
              </h2>

              <p>
              At the beating heart of Johannesburg’s historic business district,
              astride its plunging seam of gold, stands <strong>Rand Club</strong> – a timeless South African icon.
              Founded in 1887 in the fledgling days of Johannesburg, <strong>Rand Club</strong> is
              the city’s most enduring social institution and finest heritage venue.
              </p>

              <p>
              Whilst the magnificence of the Clubhouse draws the eye and stirs the
              spirit, it is the camaraderie within its walls that is the true
              hallmark of membership. Inside, you will encounter a diverse group
              of Members – creatives, entrepreneurs, professionals and
              eccentrics – each brimming with curiosity and uncommon perspectives.
              Today, <strong>Rand Club</strong> operates as an exclusive Member’s Club and destination events venue.
              </p>

              <p>
              As one explores the Clubhouse, one is left in little doubt that history
              is not merely on display. Johannesburg’s history has been made here.
              </p>

              <ReadMoreButton
                onClick={() => this.setState({ membershipOpen: true })}
                text={'Membership'}
              />
              <ReadMoreButton
                onClick={() => this.setState({ whyOpen: true })}
                text={'Why the Rand Club?'}
              />
              <ReadMoreButton
                onClick={() => this.setState({ historyOpen: true })}
                text={'History'}
              />

            </p>

            <br/><br/>

            <Row>
              <Col sm="12">
                <a
                  style={{ margin: 10 }}
                  className="btn btn-lg btn-primary"
                  href="/members"
                >
                  Members only
                </a>
              </Col>
            </Row>


          </div>
          <br></br>

          {/* membership modal */}
          <Modal isOpen={this.state.membershipOpen}>
            <ModalHeader
              toggle={() => this.setState({ membershipOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <Membership />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ membershipOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* why modal */}
          <Modal isOpen={this.state.whyOpen}>
            <ModalHeader
              toggle={() => this.setState({ whyOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <Why />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ whyOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* history modal */}
          <Modal isOpen={this.state.historyOpen}>
            <ModalHeader
              toggle={() => this.setState({ historyOpen: false })}
            ></ModalHeader>
            <ModalBody>
              <History />
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => this.setState({ historyOpen: false })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

        </main>
        <div id="footer" style={{display: "flex",justifyContent: "center",alignItems: "center"}} className="x-small-font-italic">
          <a href="https://www.randclub.co.za">
            © RAND CLUB 2024 – 2030
          </a>
        </div>
      </div>
    );
  }
}

export default Landing;
