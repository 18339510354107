import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { Container } from 'reactstrap';

import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { slide as Menu } from 'react-burger-menu'

import { About } from './about';
import { aboutStore } from '../../stores/about-store';
import { Feedback } from './feedback';
import { feedbackStore } from '../../stores/feedback-store';


import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      loggedIn: false,
      busyLoading: false,
      menuOpen: false,
    };

  }

  aboutStep() {
    this.setState({ step: 1 });
  }
  feedbackStep() {
    this.setState({ step: 2 });
  }

  previousStep() {
    this.setState({ step: this.state.step - 1 });
  }
  nextStep() {
    this.setState({ step: this.state.step + 1 });
  }

  searchStep() {
    this.setState({ step: 0 });
  }


  closeMenu () {
    document.body.classList.toggle('noscroll', false);
    this.setState({menuOpen: false});
  }
  setLoadingTrue () {
    document.body.classList.toggle('noscroll', true);
    this.setState({busyLoading: true});
  }
  setLoadingFalse () {
    document.body.classList.toggle('noscroll', false);
    this.setState({busyLoading: false});
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen});
    document.body.classList.toggle('noscroll', state.isOpen);
  }

  renderStep() {
    switch (this.state.step) {
      case 0:
        return (
          <h1>TEST!</h1>
        );
      case 1: // About page
        return (
          <About
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );
      case 2: // Feedback page
        return (
          <Feedback
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
          />
        );

      default:
        return <div>No step found.</div>;
    }
  }
  showSettings (event) {
    event.preventDefault();
  }


  render() {
    return (
      <Provider
        aboutStore={aboutStore}
        feedbackStore={feedbackStore}
      >

        <div>
        {this.state.busyLoading? (
          <div className='loading-screen'>
            <ReactLoading type="bars" color="#fff"
                  height={100} width={50} />
          </div>
        ) : ''}

        <Menu
           right
           itemListElement="div"
           isOpen={this.state.menuOpen}
           onStateChange={(state) => this.handleStateChange(state)}
        >
          <div className="d-flex justify-content-center">
            <img className='' src='logo.png' alt="logo" height="100px"/>
          </div>
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={async () => {
            this.closeMenu();

          }}>News</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();

          }}>Events</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();

          }}>Venues</a>
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();

          }}>Restaurant</a>

          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();

          }}>Membership</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.aboutStep();
          }}>About Us</a>
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.feedbackStep();
          }}>Contact Us</a>
        </Menu>


          <nav>
           <a href="/">
             <img src="logo.png" alt="logo" height="30px" />
           </a>
          </nav>

          <Container className="pt-5">

            <Row>
              <Col lg="12">
                <div className="page-container">{this.renderStep()}</div>
              </Col>
            </Row>
          </Container>
          <div id="footer"className='footer x-small-font-italic'>
            <a href="https://www.randclub.co.za">
              © RAND CLUB 2024 – 2030
            </a>
          </div>
        </div>
        <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      </Provider>
    );
  }
}

export default Test;
