// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { observer, inject } from 'mobx-react';

import { LoginStore } from '../../stores/login-store';
import { ProfileStore } from '../../stores/profile-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { TextInput, PasswordInput } from '../inputs/text';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  registrationStep: () => void;
  profileStep: () => void;
  loginStep: () => void;
  forgotPasswordStep: () => void;
  changePasswordStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  getFirstLogInState: () => boolean;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
  profileStore?: ProfileStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('loginStore', 'configurationStore', 'profileStore')
@observer
export class Login extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.loginStore) {
      return;
    }

    const loginStore = this.props.loginStore;

    //console.log("------- loginStore.lodgeResults -------");
    //console.log(loginStore.lodgeResults);
    //console.log("---------------------------------------");

    const loginInput = loginStore.loginInput;
    const validationResult = loginStore.validationResult;

    const profileStore = this.props.profileStore;

    let firstLogIn = this.props.getFirstLogInState();

    return (
      <div>
<Card>
<CardBody>
        <Row>
          <Col sm="12">
            <h1>Login</h1>
          </Col>
        </Row>

        <p className="small-font"><i>Members only.</i></p>

        <Row>
          <Col>
            <TextInput
              id="username"
              label="Email"
              value={loginInput.username}
              onChange={e =>
                (loginInput.username = e.target.value)
              }
              invalid={!!validationResult.username}
              validationMessage={validationResult.username}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <PasswordInput
              id="password"
              label="Password"
              value={loginInput.password}
              onChange={e =>
                (loginInput.password = e.target.value)
              }
              invalid={!!validationResult.password}
              validationMessage={validationResult.password}
            />
          </Col>
        </Row>


        {firstLogIn ? (
          <span className="small-font-italic">
            <p>Congratulations on successfully registering with the Freemason Travellers Guide!</p>
            <p>We're thrilled to welcome you to our community!</p>
            <p><b>To get started, please use the random password you received via email to log in.</b></p>
            <p>Thank you for choosing Freemason Travellers Guide. We look forward to providing you with an exceptional experience!</p>
            <br/>
          </span>
        ): ''}

        <div id='navigation_buttons'>
          <Button color="" disabled={loginStore.isLoading} onClick={async () => {

            this.props.setLoadingTrue();

            toast.info("Verifying credentials");
            loginStore.validate();
            const success = loginStore.checkValidation();
            console.log('----');
            console.log(success);
            console.log('----');
            if (success) {
              await loginStore.loginUser();
              if (loginStore.isLoggedIn) {
                if (loginStore.change_password) {
                  toast.warning("Change your password!");
                  this.props.changePasswordStep();
                }
                else {
                  // load the configurations
                  //await this.props.configurationStore.getConfigurations();

                  //await profileStore.getUserWatchList(loginStore.miniToken);
                  //await profileStore.getUserProfileFeed(loginStore.miniToken);

                  //await feedWallStore.getUserFeed(loginStore.miniToken);

                  toast.success("Welcome back!");
                  this.props.profileStep();
                  //this.props.feedWallStep();
                }
              } else {
                //this.props.loginStep();
                //notify();
                toast.error("Incorrect login details!");
              }
            }
            this.props.setLoadingFalse();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Login-1--Streamline-Core"><desc>Login 1 Streamline Icon: https://streamlinehq.com</desc><g id="login-1--arrow-enter-frame-left-login-point-rectangle"><path id="Subtract" fill="#8fbffa" fill-rule="evenodd" d="M1.5 0A1.5 1.5 0 0 0 0 1.5v11A1.5 1.5 0 0 0 1.5 14h7a1.5 1.5 0 0 0 1.5 -1.5V9.25h-0.516a2 2 0 0 1 -3.398 1.164l-2 -2a2 2 0 0 1 0 -2.828l2 -2A2 2 0 0 1 9.484 4.75H10V1.5A1.5 1.5 0 0 0 8.5 0h-7Z" clip-rule="evenodd" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M8.25 5a0.75 0.75 0 0 0 -1.28 -0.53l-2 2a0.75 0.75 0 0 0 0 1.06l2 2A0.75 0.75 0 0 0 8.25 9V8H13a1 1 0 1 0 0 -2H8.25V5Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Login
          </Button>

          <Button color="" onClick={async () => {
              //toast.error("Forgot your password!");
              this.props.forgotPasswordStep();
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Key--Streamline-Core"><desc>Key Streamline Icon: https://streamlinehq.com</desc><g id="key--entry-key-lock-login-pass-unlock-access"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M10.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 -1.414 1.414L11 3.414 9.914 4.5l1.293 1.293a1 1 0 0 1 -1.414 1.414L8.5 5.914 7.446 6.968a4 4 0 1 1 -1.414 -1.414l1.757 -1.757 0.004 -0.004 0.004 -0.004 2.496 -2.496ZM2 9a2 2 0 1 1 4 0 2 2 0 0 1 -4 0Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Forgot Password
          </Button>

        </div>
</CardBody>
</Card>

      </div>
    );
  }
}

export default Login;
