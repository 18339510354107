import { observable, action } from 'mobx';
//import { saveAs, encodeBase64 } from '@progress/kendo-file-saver';

import { Profile } from '../domain/profile';
import api from '../api';

export class ProfileStore {
  @observable isLoading = false;
  @observable isExpanded = [];
  @observable editResults: any = [];
  @observable referral_code?: string;
  @observable page?: number;
  @observable feed_input = '';
  @observable feed: any = [];
  @observable settingsInput: Partial<Profile> = {
    enable_notifications: false,
    send_emails: false,
    all_meetings: false,
    only_watch_list: false,
    custom_notifications: false,
    filter_constitutions: [],
    all_socials_notifications: false,
    day_socials_notifications: false,

    email_notification_weekly_meetings: false,
    all_lodge_weekly_notifications: false,
    watch_list_weekly_notifications: false,
    custom_weekly_notifications: false,
    filter_weekly_constitutions: [],
    all_socials_weekly_notifications: false,
    week_socials_weekly_notifications: false,

    email_notification_monthly_meetings: false,
    all_lodge_monthly_notifications: false,
    watch_list_monthly_notifications: false,
    custom_monthly_notifications: false,
    filter_monthly_constitutions: [],
    all_socials_monthly_notifications: false,
    month_socials_monthly_notifications: false,
    email_notification_lodge_attendance_report: false,
    email_notification_for_suggestions: false,
    display_on_leaderboard: false,
    address: '', // pretty sure we can remove this
    degree: '', // pretty sure we can remove this
    constitution: '', // pretty sure we can remove this
    district: '', // pretty sure we can remove this
    country: '' // pretty sure we can remove this
  };
  @observable userWatchList: any = [];
  @observable toggleMembershipDivs: { [k: number]: boolean } = {};

  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  @observable userPostModalInput: { [k: string]: any } = {
    id_type: 'id'
    // TODO remove init
  };
  @observable modalValidationResult: { [k: string]: string } = {};
  @observable userPostModalOpen: boolean = false;
  @observable userPostModalSuccess: boolean = false;
  @observable userPostModalError: boolean = false;

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.feed_input = (this.feed_input == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  referralCode = (referral_code) => {
    try {
      this.page = 4;
      this.referral_code = referral_code;
      //console.log('this.referral_code');
      //console.log(this.referral_code);
    } catch (error) {
      // Handle the error here or log it
      console.error("Error updating state:", error);
    }
  };

  emailNotification = (
    enable_notifications,
    send_emails,
    all_meetings,
    only_watch_list,
    custom_notifications,
    filter_constitutions,
    all_socials_notifications,
    day_socials_notifications,

    email_notification_weekly_meetings,
    all_lodge_weekly_notifications,
    watch_list_weekly_notifications,
    custom_weekly_notifications,
    filter_weekly_constitutions,
    all_socials_weekly_notifications,
    week_socials_weekly_notifications,

    email_notification_monthly_meetings,
    all_lodge_monthly_notifications,
    watch_list_monthly_notifications,
    custom_monthly_notifications,
    filter_monthly_constitutions,
    all_socials_monthly_notifications,
    month_socials_monthly_notifications,
    email_notification_lodge_attendance_report,
    email_notification_for_suggestions,
    display_on_leaderboard
  ) => {
    try {
      this.settingsInput.enable_notifications = enable_notifications;
      this.settingsInput.send_emails = send_emails;
      this.settingsInput.all_meetings = all_meetings;
      this.settingsInput.only_watch_list = only_watch_list;
      this.settingsInput.custom_notifications = custom_notifications;
      this.settingsInput.filter_constitutions = filter_constitutions;
      this.settingsInput.all_socials_notifications = all_socials_notifications;
      this.settingsInput.day_socials_notifications = day_socials_notifications;

      this.settingsInput.email_notification_weekly_meetings = email_notification_weekly_meetings;
      this.settingsInput.all_lodge_weekly_notifications = all_lodge_weekly_notifications;
      this.settingsInput.watch_list_weekly_notifications = watch_list_weekly_notifications;
      this.settingsInput.custom_weekly_notifications = custom_weekly_notifications;
      this.settingsInput.filter_weekly_constitutions = filter_weekly_constitutions;
      this.settingsInput.all_socials_weekly_notifications = all_socials_weekly_notifications;
      this.settingsInput.week_socials_weekly_notifications = week_socials_weekly_notifications;

      this.settingsInput.email_notification_monthly_meetings = email_notification_monthly_meetings;
      this.settingsInput.all_lodge_monthly_notifications = all_lodge_monthly_notifications;
      this.settingsInput.watch_list_monthly_notifications = watch_list_monthly_notifications;
      this.settingsInput.custom_monthly_notifications = custom_monthly_notifications;
      this.settingsInput.filter_monthly_constitutions = filter_monthly_constitutions;
      this.settingsInput.all_socials_monthly_notifications = all_socials_monthly_notifications;
      this.settingsInput.month_socials_monthly_notifications = month_socials_monthly_notifications;
      this.settingsInput.email_notification_lodge_attendance_report = email_notification_lodge_attendance_report;
      this.settingsInput.email_notification_for_suggestions = email_notification_for_suggestions;
      this.settingsInput.display_on_leaderboard = display_on_leaderboard;
    } catch (error) {
      // Handle the error here or log it
      console.error("Error updating state:", error);
    }

    console.log('!!!!this.settingsInput.filter_constitutions!!!!');
    console.log(this.settingsInput.filter_constitutions);
  };

  toggleExpand = (index) => {
    this.isExpanded[index] = !this.isExpanded[index];
  };

  updateNotifications = async (miniToken) => {
    let dat = {
      miniToken: miniToken,
      enable_notifications: this.settingsInput.enable_notifications,
      send_emails: this.settingsInput.send_emails,
      all_meetings: this.settingsInput.all_meetings,
      only_watch_list: this.settingsInput.only_watch_list,
      custom_notifications: this.settingsInput.custom_notifications,
      filters: {
        filter_constitutions: this.settingsInput.filter_constitutions,
      },
      all_socials_notifications: this.settingsInput.all_socials_notifications,
      day_socials_notifications: this.settingsInput.day_socials_notifications,

      email_notification_weekly_meetings: this.settingsInput.email_notification_weekly_meetings,
      all_lodge_weekly_notifications: this.settingsInput.all_lodge_weekly_notifications,
      watch_list_weekly_notifications: this.settingsInput.watch_list_weekly_notifications,
      custom_weekly_notifications: this.settingsInput.custom_weekly_notifications,
      filters_weekly: {
        filter_weekly_constitutions: this.settingsInput.filter_weekly_constitutions,
      },
      all_socials_weekly_notifications: this.settingsInput.all_socials_weekly_notifications,
      week_socials_weekly_notifications: this.settingsInput.week_socials_weekly_notifications,

      email_notification_monthly_meetings: this.settingsInput.email_notification_monthly_meetings,
      all_lodge_monthly_notifications: this.settingsInput.all_lodge_monthly_notifications,
      watch_list_monthly_notifications: this.settingsInput.watch_list_monthly_notifications,
      custom_monthly_notifications: this.settingsInput.custom_monthly_notifications,
      filters_monthly: {
        filter_monthly_constitutions: this.settingsInput.filter_monthly_constitutions,
      },
      all_socials_monthly_notifications: this.settingsInput.all_socials_monthly_notifications,
      month_socials_monthly_notifications: this.settingsInput.month_socials_monthly_notifications,
      email_notification_lodge_attendance_report: this.settingsInput.email_notification_lodge_attendance_report,
      email_notification_for_suggestions: this.settingsInput.email_notification_for_suggestions,
      display_on_leaderboard: this.settingsInput.display_on_leaderboard
    };

    let result = await api.updateNotifications(dat);
  };

  updateWatchList = async (miniToken) => {
    let dat = {
      miniToken: miniToken,
      action: 'remove'
    };

    let result = await api.updateWatchList(dat);
  };

  getUserWatchList = async (miniToken) => {
    let dat = {
      miniToken: miniToken
    };

    let result = await api.getUserWatchList(dat);

    this.userWatchList = result;
  };

  addNotification = () => {};

  removeNotification = (index) => {};

  suggestMembershipRemoval = async (lodge_id, user_id, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken, // for created by user
          suggestion_type : '5',
          action : '1',
          lodge_id: lodge_id, // lodge
          user_id: user_id, // user to remove
          proposer: '', // not needed to pass through
          seconder: '', // not needed to pass through
          joined: '' // not needed to pass through
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //const result2 = await api.claimMembership(result.token, dat);
      const result2 = await api.makeSuggestion('', dat);
      console.log(result2);
      console.log('======= makeSuggestion =======');
      console.log(result2.status);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  getUserProfileFeed = async (miniToken) => {
    let dat = {
      miniToken: miniToken
    };

    let result = await api.getUserProfileFeed(dat);

    this.feed = result;
  };

  postToUserProfileFeed = async (miniToken, description) => {
    console.log('image_file_name');
    console.log(this.userPostModalInput.image_file_name);

    //const dataURI = "data:text/plain;base64," + encodeBase64("Hello World!");
    //saveAs(dataURI, "/testtesttest.txt");

    let dat = {
      miniToken: miniToken,
      description: description,
      image_file_name: this.userPostModalInput.image_file_name,
      image_file_data: this.userPostModalInput.image_file_data
    };

    await api.postToUserProfileFeed(dat);
  };

  toggleUserPostModal = () => {
    this.userPostModalOpen = !this.userPostModalOpen;
    this.userPostModalError = false;
    this.userPostModalSuccess = false;
  };

}

export const profileStore = new ProfileStore();
(window as any).contributeStore = profileStore;
