// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { AboutStore } from '../../stores/about-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  aboutStore?: AboutStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('aboutStore')
@observer
export class About extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.aboutStore) {
      return;
    }

    const aboutStore = this.props.aboutStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>ABOUT THE RAND CLUB</u></h4>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <h5>A 21st century club. An extraordinary venue.</h5>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <span>
              <p>
              At the beating heart of Johannesburg’s historic business district, astride its plunging seam of gold, stands Rand Club – a timeless South African icon.
              Founded in 1887 in the fledgling days of Johannesburg, Rand Club is the city’s most enduring social institution and finest heritage venue.
              </p>
              <p>
              Whilst the magnificence of the Clubhouse draws the eye and stirs the spirit, it is the camaraderie within its walls that is the true hallmark of membership. Inside, you will encounter a diverse group of Members – creatives, entrepreneurs, professionals and eccentrics – each brimming with curiosity and uncommon perspectives. Today, Rand Club operates as an exclusive Member’s Club and destination events venue.
              </p>
              <p>
              As one explores the Clubhouse, one is left in little doubt that history is not merely on display. Johannesburg’s history has been made here.
              </p>
              <p>
              To learn more about Rand Club’s rich history, click here.
              </p>
            </span>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <h5>Rand Club Timeline</h5>
            <span>
              <p>
              October 1887
              <br/>
              The Club was founded.
              </p>
              <p>
              February 1890
              <br/>
              The Second Clubhouse was erected.
              </p>
              <p>
              January 1896
              <br/>
              Jameson Raid - Reform Committee members arrested at Rand Club.
              </p>
              <p>
              December 1904
              <br/>
              The Third Clubhouse was first occupied.
              </p>
              <p>
              May 1913
              <br/>
              Miners' strike - Rand Club besieged.
              </p>
              <p>
              April 1947
              <br/>
              Royal visit to Rand Club.
              </p>
              <p>
              June 2005
              <br/>
              Fire devastated the Clubhouse.
              </p>
              <p>
              2006
              <br/>
              Clubhouse restored and refurbished.
              </p>
            </span>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <h5>RAND CLUB'S KITCHEN</h5>
            <span>
              <p>
              Offers members and the public light meals that runs from Monday to Saturday 07h00 to 21h00. Members receive a 20% discount on all food and beverage.
              </p>
            </span>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <h5>DRESS CODE & DEVICE USAGE</h5>
            <span>
              <p>
              Rand Club is an elegant, formal building showcasing Edwardian architecture in Johannesburg at its finest. Every effort is made to ensure that your Rand Club experience is memorable.
              </p>
              <p>
              Rand Club is a private members’ club. As with any club, there are rules to be observed. To view a full list of Club Rules and Regulations, please contact Club administrators.
              </p>
            </span>
          </Col>
        </Row>

        <hr />
        <br />
        <div className="small-font">
          <Row>
            <Col>
            <b>VISIT US</b>
            <br/>
            33 Loveday Street & Fox Street,
            <br/>
            Marshalltown, Johannesburg,
            <br/>
            2107
            <br/>
            Get Directions
            </Col>
            <Col>
            <b>CALL US</b>
            <br/>
            <b>Membership Enquiries:</b>
            <br/>
            011 870 4260
            <br/>
            <b>WhatsApp Enquiries:</b>
            <br/>
            064 005 3128
            <br/>
            <b>Event Enquiries:</b>
            <br/>
            011 870 4260
            </Col>
            <Col>
            <b>EMAIL</b>
            <br/>
            <b>General Enquiries:</b> phil@randclub.co.za
            <br/>
            <b>Membership Enquiries:</b> membership@randclub.co.za
            <br/>
            <b>Event Enquiries:</b> events@randclub.co.za
            <br/>
            <b>Accommodation Enquiries:</b> reception@randclub.co.za
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default About;
