import { observable, action } from 'mobx';
import { Login } from '../domain/login';
import api from '../api';
import bcrypt from 'bcryptjs';

import Cookies from 'js-cookie';

export class LoginStore {
  @observable isLoading = false;
  @observable isLoggedIn = false;
  @observable miniToken = '';
  @observable referral_code = '';
  @observable username = '';
  @observable email = '';
  @observable level = 0;
  @observable points = 0;
  @observable percentage = 0;
  @observable points_high = 0;
  @observable sponsored_posts = [];
  // GLOBAL
  @observable enable_notifications = false;
  // DAILY
  @observable email_notification_daily_meetings = false;
  @observable all_lodge_notifications = false;
  @observable watch_list_notifications = false;
  @observable custom_notifications = false;
  @observable filter_constitutions = [];
  @observable all_socials_notifications = false;
  @observable day_socials_notifications = false;
  // WEEKLY
  @observable email_notification_weekly_meetings = false;
  @observable all_lodge_weekly_notifications = false;
  @observable watch_list_weekly_notifications = false;
  @observable custom_weekly_notifications = false;
  @observable filter_weekly_constitutions = [];
  @observable all_socials_weekly_notifications = false;
  @observable week_socials_weekly_notifications = false;
  // MONTHLY
  @observable email_notification_monthly_meetings = false;
  @observable all_lodge_monthly_notifications = false;
  @observable watch_list_monthly_notifications = false;
  @observable custom_monthly_notifications = false;
  @observable filter_monthly_constitutions = [];
  @observable all_socials_monthly_notifications = false;
  @observable month_socials_monthly_notifications = false;
  // REPORTS
  @observable email_notification_lodge_attendance_report = false;
  // SYSTEM
  @observable email_notification_for_suggestions = false;
  @observable display_on_leaderboard = false;

  @observable last_login = '';
  @observable number_of_lodges = 0;
  @observable number_of_suggestions = 0;
  @observable number_of_votes = 0;
  @observable number_of_referrals = 0;
  @observable lodges = [];
  @observable lodge_data = [];
  @observable change_password = true;
  @observable two_fa = '';
  @observable number_of_locations_visited = 0;
  @observable number_of_lodges_visited = 0;
  @observable number_of_total_visited = 0;
  @observable visitationMeetings = [];
  @observable visitationMeetingsPast = [];
  @observable visitationMeetingsFuture = [];

  @observable loginInput: Partial<Login> = {
    username: '',
    password: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];

  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.loginInput;
    this.validationResult = {};
    this.fieldsToFill = [];

    this.validationResult.username = (request.username == '')
      ? '* Required'
      : '';

    this.validationResult.password = (request.password == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  loginUser = async () => {

    this.isLoading = true;
    let result = null;
    try {

        //let resulta = await api.getToken(null);
        //console.log('======= getToken =======');
        //console.log(resulta.token);
        //console.log('============================');

        let dat = {
          user: this.loginInput.username,
          password: this.loginInput.password
        };
        result = await api.loginUser(null, dat);

      //return true;
    } catch (error) {
      console.error(error);
      //return false;
    } finally {

      console.log('======= loginUser =======');
      //this.editResults = result;
      console.log(result);
      if(result != null) {
        if(result.status === true)
        {
          this.setLoggedInStatus();
          console.log(this.isLoggedIn);
          this.username = result.username;
          //this.email = result.email;
          this.change_password = result.change_password;
        }
        else {
          this.setLoggedOutStatus();
        }
        console.log('============================');
      }
      else {
        this.setLoggedOutStatus();
      }
      this.isLoading = false;

      this.loginInput.password = "";
    }
  };

  setLoggedInStatus = () => {
    this.isLoggedIn = true;
    console.log('changed logged in status to true');
  };

  setLoggedOutStatus = () => {
    this.isLoggedIn = false;
    console.log('changed logged in status to false');
  };


}

export const loginStore = new LoginStore();
(window as any).contributeStore = loginStore;
