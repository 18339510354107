/* global fetch:false Request:false */
const key = process.env.REACT_APP_API_KEY;
const auth = 'Bearer ' + key;
const sandbox = process.env.REACT_APP_SANDBOX;
const basePath = process.env.REACT_APP_API_URL;

const makeRequest = (method, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth,
      //'Access-Control-Allow-Origin': 'https://randclub.co.za', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
    },
    body: data && JSON.stringify(data),
  };
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    console.log(request);
    console.log(options);
    console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

const makeRequestWithToken = (method, token, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': auth,
      // 'Access-Control-Allow-Origin': 'https://randclub.co.za', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
      /*'CSRF-TOKEN': token*/
    },
    body: data && JSON.stringify(data),
  };
  console.log('************');
  console.log(JSON.stringify(data));
  console.log('************');
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    // console.log(request);
    // console.log(options);
    // console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

const makeRequestWithFileData = (method, data, path, cb) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': auth,
      //'Access-Control-Allow-Origin': 'https://freemason-alpha.com', // Replace with your desired origin
      'Access-Control-Allow-Origin': '*', // Replace with your desired origin
      'Access-Control-Allow-Methods': '*',
      'Accept': '*/*',
    },
    body: data && JSON.stringify(data),
  };
  const request = new Request(basePath + path);
  return fetch(request, options).then((response) => {
    console.log(request);
    console.log(options);
    console.log(response);
    return response.json().then((r) => {
      if (response.status === 200) {
        return r;
      } else {
        return Promise.reject(r);
      }
    });
  });
};

export default {
  loginUser: (token, data) => makeRequestWithToken('POST', token, data, '/api/loginuser'),
  //confirmUser: (token, data) => makeRequestWithToken('POST', token, data, '/api/confirmuser'),
  //confirmCode: (token, data) => makeRequestWithToken('POST', token, data, '/api/confirmcode'),
  //changePassword: (token, data) => makeRequestWithToken('POST', token, data, '/api/changenreset'),
  // Configuration
  //getConfigurations: (data) => makeRequest('GET', null, '/api/getConfigurations'),
  // 2FA Code
  // generateTwoFA: data => makeRequest('GET', data, '/api/gen2FA'),
  // Confirm Referral Code
  //confirmReferralCode: (token, data) => makeRequestWithToken('POST', token, data, '/api/register-pre'),
  // Register User
  //registerUser: (token, data) => makeRequestWithToken('POST', token, data, '/api/register'),
  // 2FA Code Confirmation
  //confirm2FACode: (token, data) => makeRequestWithToken('POST', token, data, '/api/register-confirmation')
};
