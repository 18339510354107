import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'reactstrap';
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'mobx-react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { slide as Menu } from 'react-burger-menu'

import { configurationStore } from '../../stores/configuration-store';

import { Profile } from './profile';
import { profileStore } from '../../stores/profile-store';

import { RegistrationPre } from './registration-pre';
import { registrationPreStore } from '../../stores/registration-pre-store';
import { Registration } from './registration';
import { registrationStore } from '../../stores/registration-store';
import { RegistrationTwo } from './registration-two';
import { registrationTwoStore } from '../../stores/registration-two-store';
import { RegistrationThree } from './registration-three';
import { registrationThreeStore } from '../../stores/registration-three-store';

import { Login } from './login';
import { loginStore } from '../../stores/login-store';

import { ResetPasswordEmail } from './reset-password-email';
import { resetPasswordEmailStore } from '../../stores/reset-password-email-store';
import { ResetPasswordConfirm } from './reset-password-confirm';
import { resetPasswordConfirmStore } from '../../stores/reset-password-confirm-store';
import { ResetPasswordNewPassword } from './reset-password-new-password';
import { resetPasswordNewPasswordStore } from '../../stores/reset-password-new-password-store';

import { ContactMeModal } from '../modals/contact-me';


import { policyholderStore } from '../../stores/policyholder-store';



class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.step
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ step: nextProps.step });
  }

  get percentageDone() {
    const result = Math.floor((this.props.step / (this.props.steps.length - 1)) * 100);
    console.log(result);
    return result > 0 ? result : 0;
  }
  render() {
    return (
      <div className="d-flex justify-content-around">
        {this.props.steps.map((s, i) => (
          <div
            className={
              'progress-step-container' +
              (this.props.step > i ? ' done' : '') +
              (this.props.step === i ? ' active' : '')
            }
            key={i}
          >
            <div className="progress-step">
              <div className="progress-dot" />
              <div className="progress-line" />
            </div>
            <div className="progress-name">{s}</div>
          </div>
        ))}

      </div>
    );
  }
}

class Application extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      busyLoading: false,
      step: (this.handleReferralCode()[0] ? 40 : this.handleConfirmationCode()[0] ? 42 : 49),
      menuOpen: false,
      data: {}
    };

  }


  handleReferralCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    const refcode = queryParams.get('refcode');
    const confirmationcode = queryParams.get('confirmation');
    let result;

    // Do something with the refcode, e.g., set it to state or perform some actions
    if (refcode) {
      // Referral code is present, you can set it to state or use it as needed
      console.log('Referral Code:', refcode);
      //this.setState({ step: 40 });
      //this.state.data.refcode = refcode;
      this.setState({ refcode: refcode });
      result = [true,refcode];
    } else {
      // No referral code found in the query string
      console.log('No referral code found.');
      result = [false,''];
    }
    return result;
  };  
  // Add a function to handle the confirmation code logic
  handleConfirmationCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    const confirmationcode = queryParams.get('confirmation');
    let result;

    // Do something with the confirmationcode, e.g., set it to state or perform some actions
    if (confirmationcode) {
      // Confirmation code is present, you can set it to state or use it as needed
      console.log('Confirmation Code:', confirmationcode);
      //this.setState({ step: 40 });
      //this.state.data.refcode = refcode;
      this.setState({ confirmationcode: confirmationcode });
      result = [true,confirmationcode];
    } else {
      // No confirmation code found in the query string
      console.log('No confirmation code found.');
      result = [false,''];
    }
    return result;
  };

  handleClearingQSReferralCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('refcode');
    //const newUrl = `${location.pathname}?${queryParams.toString()}`;
    const newUrl = `${location.pathname}`;
    window.history.replaceState({}, '', newUrl);
  };

  handleClearingConfirmationCode() {
    const location = window.location; // Get the current location
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('confirmation');
    //const newUrl = `${location.pathname}?${queryParams.toString()}`;
    const newUrl = `${location.pathname}`;
    window.history.replaceState({}, '', newUrl);
  };
  

  setData(data) {
    this.setState({ data });
  }
  getStep() {
    return this.state.step;
  }
  previousStep() {
    this.setState({ step: this.state.step - 1 });
  }
  nextStep() {
    this.setState({ step: this.state.step + 1 });
  }
  registrationStep() {
    this.setState({ step: 40 });
  }
  loginStep() {
    this.setState({ step: 49 });
  }
  logoutStep() {
    this.setState({ loggedIn: false });
    //this.setState({ step: 0 });
    this.setState({ step: 49 });
  }
  profileStep() {
    this.setState({ loggedIn: true });
    this.setState({ step: 50 });
  }
  forgotPasswordStep() {
    this.setState({ loggedIn: false });
    this.setState({ step: 60 });
  }
  changePasswordStep() {
    this.setState({ step: 70 });
  }
  
  getFirstLogInState() {
    return (this.state.step == 43) ? true : false;
  }
  getConfirmationCode() {
    return this.state.confirmationcode;
  }
  closeMenu () {
    document.body.classList.toggle('noscroll', false);
    this.setState({menuOpen: false});
  }
  setLoadingTrue () {
    document.body.classList.toggle('noscroll', true);
    this.setState({busyLoading: true});
  }
  setLoadingFalse () {
    document.body.classList.toggle('noscroll', false);
    this.setState({busyLoading: false});
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen});
    document.body.classList.toggle('noscroll', state.isOpen);
  }

  renderProgressBar(){   
    if (this.state.step >= 40 && this.state.step < 49) {
      return (<ProgressBar
        step={this.state.step - 40}
        steps={[
          'Referral Code',
          'Profile Details',
          'Confirm Email',
          'Login'
        ]}
      />);
    }
    else if (this.state.step >= 60 && this.state.step < 70) {
      return (<ProgressBar
        step={this.state.step - 60}
        steps={[
          'Email',
          'Confirmation Code',
          'New Password'
        ]}
      />);
    }
    else {
      return null;
    }
  }

  renderStep() {
    switch (this.state.step) {      
      case 40: // registration - referral
        return (
          <RegistrationPre
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            getRefCode={this.getRefCode.bind(this)}
          />
        );
      case 41: // registration - profile details
        return (
          <Registration
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      case 42: // registration - 2FA - enter email code
        return (
          <RegistrationThree
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
            getConfirmationCode={this.getConfirmationCode.bind(this)}
          />
        );
      case 43: // login
        return (
          <Login
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            registrationStep={this.registrationStep.bind(this)}
            profileStep={this.profileStep.bind(this)}
            forgotPasswordStep={this.forgotPasswordStep.bind(this)}
            changePasswordStep={this.changePasswordStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
            getFirstLogInState={this.getFirstLogInState.bind(this)}
          />
        );
      case 49: // login
        return (
          <Login
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            registrationStep={this.registrationStep.bind(this)}
            profileStep={this.profileStep.bind(this)}
            forgotPasswordStep={this.forgotPasswordStep.bind(this)}
            changePasswordStep={this.changePasswordStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
            getFirstLogInState={this.getFirstLogInState.bind(this)}
          />
        );
      case 50: // profile
        return (
          <Profile
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}            
            logoutStep={this.logoutStep.bind(this)}
            setLoadingTrue={this.setLoadingTrue.bind(this)}
            setLoadingFalse={this.setLoadingFalse.bind(this)}
          />
        );
      case 60: // forgot password - enter email
        return (
          <ResetPasswordEmail
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      case 61: // forgot password - enter confirmation code
        return (
          <ResetPasswordConfirm
            nextStep={this.nextStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );
      case 70: // change password - enter new password
        return (
          <ResetPasswordNewPassword
            previousStep={this.previousStep.bind(this)}
            nextStep={this.nextStep.bind(this)}            
            logoutStep={this.logoutStep.bind(this)}
            loginStep={this.loginStep.bind(this)}
          />
        );

      default:
        return <div>No step found.</div>;
    }
  }
  showSettings (event) {
    event.preventDefault();
  }


  render() {
    return (
      <Provider        
        configurationStore={configurationStore}
        profileStore={profileStore}
        registrationPreStore={registrationPreStore}
        registrationStore={registrationStore}
        registrationTwoStore={registrationTwoStore}
        registrationThreeStore={registrationThreeStore}
        loginStore={loginStore}
        resetPasswordEmailStore={resetPasswordEmailStore}
        resetPasswordConfirmStore={resetPasswordConfirmStore}
        resetPasswordNewPasswordStore={resetPasswordNewPasswordStore}
        
        policyholderStore={policyholderStore}
      >
        <div>
        {this.state.busyLoading? (
          <div className='loading-screen'>
            <ReactLoading type="bars" color="#fff"
                  height={100} width={50} />
          </div>
        ) : ''}

        {this.state.loggedIn == true ?
        <Menu
           right
           itemListElement="div"
           isOpen={this.state.menuOpen}
           onStateChange={(state) => this.handleStateChange(state)}
        >
          <div className="d-flex justify-content-center">
            <img className='circle' src='logo.png' alt="logo" height="60px" onClick={() => {
                this.closeMenu();
                this.state.loggedIn == true
                  ? this.profileStep()
                  : this.loginStep();
              }}/>
          </div>
          <div className="d-flex justify-content-center">
            <h6>
              <b>
                <a id="contact" className="profile-text bm-item" href="#" onClick={() => {
                    this.closeMenu();
                    this.state.loggedIn == true
                      ? this.profileStep()
                      : this.loginStep();
                  }}>
                  {loginStore.username}
                </a>
              </b>
            </h6>
          </div>
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.profileStep()
              : this.loginStep();
          }}>Profile</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.profileStep()
              : this.loginStep();
          }}>News</a>
          <a id="about" className="bm-item" href="#" onClick={async () => {
            this.closeMenu();
            //contributeStore.setEdits(loginStore);
            this.profileStep();
          }}>Events</a>
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={async () => {
            this.closeMenu();
            //impromptuMeetingStore.setImpromptuMeetings(loginStore);
            this.profileStep();
          }}>Venues</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.profileStep()
              : this.loginStep();
          }}>Restaurant</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            //leaderboardStore.setAllLeaderboards(loginStore);
            this.state.loggedIn == true
              ? this.profileStep()
              : this.loginStep();
          }}>Shop</a>
          <hr/>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.profileStep()
              : this.loginStep();
          }}>FAQs</a>
          <a id="home" className="bm-item" href="#" onClick={() => {
            this.closeMenu();
            this.state.loggedIn == true
              ? this.profileStep()
              : this.loginStep();
          }}>Feedback</a>
          <hr/>
          <a className="menu-item--small" href="#" onClick={() => {
            this.closeMenu();
            loginStore.setLoggedOutStatus();
            this.logoutStep();
          }}>Logout</a>
        </Menu>
        : '' }

          <nav>
           <a href="/">
             <img src="logo.png" alt="logo" height="30px" />
           </a>
          </nav>

          <Container className="pt-5">
            {this.renderProgressBar()}
            <Row>              
              <Col lg="12">
                <div className="page-container">{this.renderStep()}</div>
                <ContactMeModal />                
              </Col>              
            </Row>
          </Container>
          <div id="footer" className='footer x-small-font-italic'>
            <a href="https://www.randclub.co.za">
              © RAND CLUB 2024 – 2030
            </a>
          </div>
        </div>
        <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        />
      </Provider>
    );
  }
}

export default Application;
