// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { observer, inject } from 'mobx-react';

import { FeedbackStore } from '../../stores/feedback-store';

import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  feedbackStore?: FeedbackStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('feedbackStore')
@observer
export class Feedback extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.feedbackStore) {
      return;
    }

    const feedbackStore = this.props.feedbackStore;
    const feedbackInput = feedbackStore.feedbackInput;
    const validationResult = feedbackStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Contact us</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <span className="small-font-italic">
        We look forward to hearing from you.
        </span>
        <br/><br/>
        <Row>
          <Col>
            <TextArea
              id="message"
              label="Message:"
              value={feedbackInput.message}
              onChange={e =>
                (feedbackInput.message = e.target.value)
              }
              invalid={!!validationResult.message}
              validationMessage={validationResult.message}
            />
          </Col>
        </Row>
        <br/>
        <Button color="primary" onClick={async () => {
          toast.success("Your feedback has been submitted!");

          /*if (await feedbackStore.provideFeedback(
                feedbackInput.message,
                loginStore
              )) {
              toast.success("Your feedback has been submitted!");
              thankYouStore.typeOfThankYou = 'feedback';
              this.props.thankYouStep();
              feedbackInput.message = ""; // reset the feedback message after submission
          }
          else {
            toast.warning("Could not submit your feedback!");
          }*/
        }}>
          Submit
        </Button>

        </CardBody>
        </Card>

        <hr />
        <br />       

        <div className="small-font">
          <Row>
            <Col>
            <b>VISIT US</b>
            <br/>
            33 Loveday Street & Fox Street,
            <br/>
            Marshalltown, Johannesburg,
            <br/>
            2107
            <br/>
            Get Directions
            </Col>
            <Col>
            <b>CALL US</b>
            <br/>
            <b>Membership Enquiries:</b>
            <br/>
            011 870 4260
            <br/>
            <b>WhatsApp Enquiries:</b>
            <br/>
            064 005 3128
            <br/>
            <b>Event Enquiries:</b>
            <br/>
            011 870 4260
            </Col>
            <Col>
            <b>EMAIL</b>
            <br/>
            <b>General Enquiries:</b> phil@randclub.co.za
            <br/>
            <b>Membership Enquiries:</b> membership@randclub.co.za
            <br/>
            <b>Event Enquiries:</b> events@randclub.co.za
            <br/>
            <b>Accommodation Enquiries:</b> reception@randclub.co.za
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Feedback;
